<template>
  <div class="footer">
    <div class="information-box">
      <div class="container">
        <div class="single-block">
          <h6>Locatie</h6>
          <a href="https://maps.app.goo.gl/fSt4VCWbHAYhD8qy6" target="_blank">Zwarteweg 15,  <br>
            8097 PR Oosterwolde</a>
        </div>
        <div class="single-block">
          <h6>Landgoed Morren Run</h6>
          <router-link to="/privacy" style="font-size: 20px;">Privacy verklaring</router-link>
        </div>
        <div class="single-block">
          <h6>Bereikbaar via:</h6>
          <a href="mailto:lmr@vsco.nl " target="_blank">lmr@vsco.nl</a>
        </div>
      </div>
    </div>
    <div class="werkdeal" style="background-color: #131313; padding: 16px; text-align: center">
      <p style="font-size: 16px"><a href="https://werkdeal.com" target="_blank" style="color: white; text-decoration: none;">Ontwikkeld door Werkdeal</a></p>
    </div>
  </div>
</template>

<style scoped lang="scss">

$gold: #C2A484;
$gold-d2: #E3D4C2;
$coal: #217287;

.footer {
  margin-top: 120px;
  width: 100%;

  .action-box {
    background-color: #202020;
    padding: 0;
    .container {
      padding: 0;
    }
    a {
      text-decoration: none;
    }
  }

  .contact-box {
    width: 100%;
    max-width: 900px;
    height: 200px;
    margin: -100px auto auto;
    position: relative;
    background-color: #217287;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(80px);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 60px 20px;
    h3 {
      font-size: 38px;
      color: $gold-d2;
      @media (max-width: 700px) {
        font-size: 28px;
      }
    }
  }
  .information-box {
    background-color: #202020;
    padding: 100px 0 50px;
    @media (max-width: 1100px) {
      padding: 100px 0;
    }
    .container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 30px;
      justify-items: stretch;
      @media (max-width: 1100px) {
        grid-template-columns: 1fr;
        gap: 40px;
      }

      .single-block {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
        .social-icons {
          display: flex;
          gap: 30px;
        }

        h6, p, a {
          text-align: center;
          font-family: 'Avenir LT Std', sans-serif;
          color: white;
        }

        h6 {
          font-size: 40px;
          margin-bottom: 10px;
          @media (max-width: 700px) {
          font-size: 35px;
            margin-bottom: 0;
          }
        }

        a {
          opacity: .7;
          transition: .3s;
          font-size: 30px;
          line-height: 35px;
          text-decoration: none;
          @media (max-width: 700px) {
            font-size: 25px;
            line-height: 30px;
          }
          &:hover {
            opacity: 1;
          }
        }

        svg {
          cursor: pointer;
          transition: .3s;

          &:hover {
            * {
              opacity: 1;
            }
          }

          * {
            cursor: pointer;
            fill: white;
            transition: .3s;
            opacity: .8;
          }
        }
      }
    }
  }
}
</style>

<script>
export default {
  name: 'FooterBar',
}
</script>
