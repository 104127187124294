<template>
  <div class="landing">
    <div class="introduction-image">
      <div class="centered-text position-absolute">
        <h1>Landgoed morren run</h1>
        <p>Ren je 5 juli met ons mee door Oosterwolde GLD?</p>
      </div>
      <img alt="Landgoed morren run" src="/images/morren.jpg">
    </div>
    <div class="container-xxl py-5">
      <div class="container">
        <div class="row g-5 align-items-center">
          <div class="col-lg-6">
            <h6 class="section-title text-start text-primary text-uppercase"
                style="font-weight: 800; color: black !important;">Oosterwolde</h6>
            <h1 class="mb-4" style="font-weight: 700">Over de run</h1>
            <p class="mb-4" style="font-size: 18px">
              Op zaterdag 5 juli 2025 wordt in Oosterwolde voor jong en oud de Landgoed Morren Run georganiseerd. De
              organisatie heeft een mooi parcours uitgezet vanaf sportpark De Heughte door de nieuwe wijk Oosterburgh en
              via het prachtige landgoed Morren weer terug naar het sportpark.
              <br>
              <br>
              De deelnemers aan de run lopen het rondje van 2,5 km respectievelijk 1, 2 of 4 keer voor 2,5 km - 5 km -
              10 km. Voor de jeugd wordt er een lopathon georganiseerd op het sportpark.
              <br>
              <br>
              Daarnaast is er ook een wandeltocht van circa 9 km, voor degenen die liever wandelend van het mooie
              landschap willen genieten.</p>
            <a class="btn btn-primary py-3 px-5 mt-2" href="https://inschrijven.nl/form/2025070551516-nl"
               target="_blank">Inschrijven</a>
          </div>
          <div class="col-lg-6">
            <img alt=""
                 src="../assets/images/Parcours.jpg"
                 style="position: relative; width: 100%;">
          </div>
        </div>
      </div>
    </div>
    <section class="video-content">
      <div class="container video-headline">
        <h4 class="video-title">Check onze video</h4>
        <span class="title-line"></span>
      </div>
      <div class="video-container container">
        <iframe allowfullscreen class="video" height="345" src="https://www.youtube.com/embed/4pbNPhRIfT4"
                title="Vacature video"
                width="420"></iframe>
      </div>
    </section>
    <div class="container"
         style="display: flex; justify-content: center; text-align: center; max-width: 700px; flex-direction: column;">
      <h2 style="font-weight: 700; font-size: 36px">Doelstelling</h2>
      <p style="font-size: 18px">De organisatoren van dit evenement zijn VSCO’61 en de Stichting Triple C Gambia.
        VSCO’61 zal een
        eventuele positieve opbrengst van dit evenement inzetten voor verdere verduurzaming van het
        sportpark (o.a. zonnepanelen en energieopslag in batterijen).<br><br> Triple C Gambia (Triple C = CCC =
        Change Children’s Chances) heeft tot doel verandering teweeg te brengen in het levensonderhoud
        van jonge en minder bevoorrechte jongeren in het Kombo South District, met name Kartong en zijn
        naburige dorpen.</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>

$blue: #a69810;
$blue-d2: #C2A484;
$coal: #E3D4C2;

.video-headline {
  display: flex;
  align-items: center;

  .title-line {
    height: 3px;
    width: 100%;
    background-color: black;
    @media (max-width: 500px) {
      display: none;
    }
  }

  .video-title {
    font-size: 44px;
    width: fit-content;
    min-width: 400px;
    @media (max-width: 500px) {
      font-size: 30px;
    }
  }
}

.video-content {
  padding: 0 0 150px;

  .video-container {
    position: relative;
    width: 500px;
    min-width: 60%;
    height: 500px;
    @media (max-width: 500px) {
      width: 100%;

    }
    max-height: 40vw;
    display: flex;
    justify-content: center;

    .video {
      position: absolute;
      border: none;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 100%;
      @media (max-width: 500px) {
        min-height: 300px;
        max-width: 90vw;
      }
    }
  }
}

.introduction-image {
  img {
    width: 100%;
    height: 450px;
    object-fit: cover;
    filter: brightness(0.5);
  }

  .centered-text {
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    text-align: center;
    width: 100%;
    padding: 0 20px;
    z-index: 9;

    h1 {
      font-size: 70px;
      line-height: normal;
      @media (max-width: 600px) {
        font-size: 45px;
      }
      color: white;
      font-weight: 800;
      margin-bottom: 20px;
      text-transform: uppercase;
    }

    p {
      font-size: 24px;
      color: white;
      font-weight: 500;
    }
  }
}

.drie-items {
  .wow {
    .fact-item {
      background-color: #eaeaea !important;
      padding: 32px 8px !important;

      .fact-icon {
        margin-bottom: 16px;
      }
    }
  }
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 20px;
  margin-top: 100px;
  @media (max-width: 1150px) {
    grid-template-columns: 1fr;
  }

  img {
    width: 100%
  }
}

.landing {
  .hero-banner-div {
    position: relative;
    @media (max-width: 600px) {
      height: 300px;
    }

    .hero-banner {
      width: 100%;
      margin-top: -100px;
      min-height: 100%;
      object-fit: cover;
      @media (max-width: 750px) {
        margin-top: 20px;
      }
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(34, 34, 34, 0.4);
    }
  }

  .hero-image {
    img {
      max-width: 600px;
      border-radius: 8px;
    }
  }

  .hero-navigator {
    background-color: white;
    width: 100%;
    margin-top: -200px;
    position: relative;
    padding: 45px 60px 60px;
    box-shadow: 0 4px 4px rgba(197, 197, 197, 0.25);

    @media (max-width: 1450px) {
      max-width: 90vw;
      margin-top: -170px;
    }

    @media (max-width: 1200px) {
      margin-top: -100px;
    }

    @media (max-width: 1000px) {
      max-width: 95vw;
      margin-top: -70px;
    }

    @media (max-width: 900px) {
      margin-top: 0;
    }

    @media (max-width: 750px) {
      padding: 25px;
      max-width: 90vw;
      margin-top: -60px;
    }


    .headline {
      h1 {
      }

      p {
        margin-bottom: 15px;
        color: #656565;

        @media (max-width: 750px) {
          margin-top: 20px;
          font-size: 20px;
        }

      }

      hr {
        border: none;
        background-color: $blue;
        width: 40px;
        height: 3px;
        margin: 0;
      }
    }

    .navigators {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      gap: 30px;
      height: 230px;
      position: relative;
      @media (max-width: 1450px) {
        height: 180px;
      }

      @media (max-width: 1200px) {
        height: 150px;
      }

      @media (max-width: 750px) {
        display: grid;
        height: unset;
        .different-navigator {
          height: 200px !important;
        }
      }


      .different-navigator {
        &::before {
          clip-path: polygon(88% 0, 100% 17%, 100% 100%, 0 100%, 0 0);
          background: #222222 !important;
        }

        &::after {
          clip-path: polygon(88% 0, 100% 17%, 100% 100%, 0 100%, 0 0);
        }
      }

      a {
        flex: 0 1 100%;
        cursor: default;
      }

      .single-navigator {
        width: 100%;
        height: 100%;
        max-width: 100%;
        position: relative;
        z-index: 4;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          background: linear-gradient(47.13deg, rgba(30, 30, 30, 0.8) 0%, rgba(207, 215, 255, 0) 97.4%);
          z-index: 1;
        }

        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  .zekerheden {
    background-color: $blue-d2;

    .container {
      padding-top: 70px;
      padding-bottom: 70px;

      b {
        color: $coal;
        font-size: 16px;
      }

      h3 {
        margin-top: 10px;
        font-weight: 800;
        font-size: 42px;
        color: white;
      }

      .items-grid {
        margin-top: 50px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 60px 80px;
        @media (max-width: 950px) {
          grid-template-columns: 1fr;
        }

        .single-item {
          border-left: solid 2px $coal;
          min-height: 140px;
          height: auto;
          display: grid;
          align-items: stretch;
          padding-left: 15px;

          h5 {
            font-weight: 800;
            font-size: 22px;
            margin-top: 10px;
            color: white;
          }

          p {
            margin-top: -5px;
            color: white;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }

  .social-media {
    margin: 50px 0 100px;

    .container {
      .social-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 950px) {
          align-items: flex-start;
          flex-direction: column;
          gap: 20px;
        }

        a {
          text-decoration: none;
          font-family: 'Avenir LT Std', sans-serif;
        }

        .right-side {
          height: auto;

          a {
            align-items: center;
            padding: 12px 50px;
            font-weight: 700;
            font-size: 20px;
            color: white;
            background-color: $blue;
            transition: .25s ease;

            &:hover {
              background-color: darken($blue, 15%);
            }
          }
        }

        .left-side {
          a {
            color: #474747;
            font-size: 22px;
            font-weight: 500;
          }

          h4 {
            font-weight: 900;
            font-size: 42px;
            color: #222222;
          }
        }
      }

      .social-content {
        display: grid;
        margin-top: 70px;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(2, 1fr);
        @media (max-width: 950px) {
          display: block;
          height: unset;
          .div4, .div5, .div5, .div6, .div7, .div8 {
            display: none;
          }
        }
        height: 400px;
        gap: 35px;

        div {
          background: rgba(217, 217, 217, 0.6);
          overflow: hidden;
        }

        img {
          width: 100%;
          margin: auto;
          object-fit: cover;
          height: 100%;
        }
      }

      .div1 {
        grid-area: 1 / 1 / 2 / 2;
      }

      .div2 {
        grid-area: 2 / 1 / 3 / 2;
      }

      .div3 {
        grid-area: 1 / 2 / 3 / 4;
      }

      .div4 {
        grid-area: 1 / 4 / 2 / 5;
      }

      .div5 {
        grid-area: 1 / 5 / 2 / 6;
      }

      .div6 {
        grid-area: 2 / 5 / 3 / 6;
      }

      .div7 {
        grid-area: 2 / 4 / 3 / 5;
      }
    }
  }

  .faq-section {
    .total-faq-headline {
      padding: 40px 0;
      background-color: #000000;
      margin-bottom: 50px;

      .container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .pill {
          width: fit-content;
          background-color: #837917;
          color: #FFE800;
          font-weight: 800;
          font-size: 16px;
          margin-bottom: 20px;
          cursor: default;
          border: none;
          padding: 10px 16px 8px;
          border-radius: 28px;
          font-family: 'Avenir LT Std', sans-serif;
        }

        h3, p {
          color: white;
        }

        h3 {
          font-size: 26px;
        }

        p {
          margin-top: 15px;
          font-family: 'Urbane', sans-serif;
          font-weight: 500;
          font-size: 16px;
        }
      }
    }

    .faq-content {
      .container {
        display: flex;
        flex-direction: column;
        max-width: 900px;

        .single-faq-item {
          cursor: pointer;
          overflow: hidden;
          border-top: solid 1px #ECEFF1;
          padding: 40px 0;

          .faq-headline {
            display: flex;
            justify-content: space-between;
            padding: 16px;

            h4 {
              font-size: 28px;
              font-weight: 600;
              @media (max-width: 700px) {
                font-size: 24px;
              }
            }

            svg {
              transition: .3s;
              transform: rotate(90deg);
              margin-right: 15px;
            }
          }

          .faq-content {
            font-size: 18px;
            font-weight: 300;
            padding: 0;
            height: 0;
            opacity: 0;
            transition: all .2s ease;
            overflow: hidden;
          }
        }

        .active-faq {
          .faq-headline {
            svg {
              transform: rotate(-90deg);
            }
          }

          .faq-content {
            height: auto;
            padding: 0 16px;
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>

<script>

// @ is an alias to /src
import AOS from "aos";

import TextImage from '@/components/TextImage.vue'
import Voordelen from "@/components/Voordelen";

export default {
  name: 'HomeView',
  components: {
    Voordelen,
    TextImage
  },
  data() {
    return {
      showedFaq: false,
    }
  },
  created() {
    // window.scrollTo(0, 0)
  },
  methods: {
    toggleFaq(id) {
      console.log(id)
      let selectedFaq = document.getElementById('faq-' + id)

      if (selectedFaq.classList.contains('active-faq')) {
        selectedFaq.classList.remove('active-faq')
      } else {
        selectedFaq.classList.add('active-faq')
      }

      this.showedFaq = !this.showedFaq
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    AOS.init();
  }
}
</script>
